html * {
  // global style
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);

    display: inline-block;
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  // ngx datatable style
  .cdk-drag-preview {
    .datatable-body-row {
      display: flex;
      flex-flow: row;
    }

    .datatable-row-center {
      display: flex;
      flex-flow: row;
    }

    .datatable-body-cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 10px;
      line-height: normal;
    }
  }
}
