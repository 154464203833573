@import "./themes.scss";

//handle themes
@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

//set color of default placeholder
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}

//disable scrollbar visibility
@mixin hideScrollbar {
  // There is a CSS rule that can hide scrollbars in Webkit-based browsers (Chrome and Safari).
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  // There is a CSS rule that can hide scrollbars in IE 10+.
  -ms-overflow-style: none;

  // Use -ms-autohiding-scrollbar if you wish to display on hover.
  // -ms-overflow-style: -ms-autohiding-scrollbar;

  // There used to be a CSS rule that could hide scrollbars in Firefox, but it has since been deprecated.
  scrollbar-width: none;
}
