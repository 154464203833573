html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

html * {
  font-family: $fugu_font_family, sans-serif;
}

.main-container {
  height: 100%;
  overflow-y: auto;
}

// --- custom  scrollbar -----
html * {
  // chrome, Edge, and Safari
  /* width */
  ::-webkit-scrollbar {
    // for vertical scrollbar
    width: 10px;
    height: 10px;
  }

  // mozilla incomplete solution
  scrollbar-width: 10px;
  .scrollbar {
    overflow-y: scroll;
    scrollbar-color: grey transparent;
    scrollbar-width: thin;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: rgba(red($light-accent-500), green($light-accent-500), blue($light-accent-500), 0.15);
    box-shadow: -3px 0px 5px 0px rgba(1, 43, 93, 0.08);
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-accent-500;
  }

  // mozilla color & width
  @-moz-document url-prefix() {
    scrollbar-color: $light-accent-500
      rgba(red($light-accent-500), green($light-accent-500), blue($light-accent-500), 0.15);
    scrollbar-width: auto;
  }

  .grid-item {
    break-inside: avoid-column;
    page-break-inside: avoid;
  }

  .double-col {
    column-count: 2;
    -moz-column-count: 2;
    -webkit-column-count: 2;

    column-gap: 20px;
    -moz-column-gap: 20px;
    -webkit-column-gap: 20px;
  }

  .bottom-buttons {
    margin-top: 20px;
  }

  .align-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .second-button {
      padding-left: 20px;
    }

    .button {
      display: block;
      justify-content: space-between;
      align-items: center;
    }
  }

  .popup-container {
    .align-buttons {
      margin-top: 10px;
    }
  }

  .top-buttons {
    float: right;
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: flex-start;

    .button-export,
    .button-shipment,
    .button-import {
      padding-left: 20px;
    }
  }

  .top-table-buttons {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: flex-start;
  }

  .warn-icon {
    color: $fugu_warning;
  }
}

.border-bottom-filters {
  border-bottom: solid;
  border-bottom-width: 2px;
  @include themify($themes) {
    border-bottom-color: themed("designBorderLineColor") !important;
  }
}

.quantity-wrapper {
  display: flex;
  align-items: center;
  .warning {
    margin-left: 6px;
    @include themify($themes) {
      color: themed("redImportantMessageColor");
    }
  }
  .quantity-wrapper {
    width: 80px;
    margin-right: 6px;
    font-weight: bold;
    &.none {
      @include themify($themes) {
        color: themed("noQuantityColor");
      }
    }
    &.partial {
      @include themify($themes) {
        color: themed("partialQuantityColor");
      }
    }
    &.full {
      @include themify($themes) {
        color: themed("fullQuantityColor");
      }
    }
  }
}

.text-color {
  @include themify($themes) {
    color: themed("textColor");
  }
}

.popper {
  span.password-popper {
    div {
      &.valid {
        @include themify($themes) {
          color: themed("validValueColor") !important;
        }
      }
      &.invalid {
        @include themify($themes) {
          color: themed("invalidValueColor") !important;
        }
      }

      padding-bottom: 2px;
      font-weight: normal;
      font-size: 15px;
    }
  }
}

.or-separator {
  font-size: 10px;
  text-align: center;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  font-weight: 550;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @include themify($themes) {
    background-color: themed("orSeparatorBackgroundColor");
    color: themed("orSeparatorColor");
  }
}
