:host ::ng-deep ui-status-label {
  //margin-left: 10px;

  .status-sent {
    @include themify($themes) {
      color: themed("sentStatusColor");
      .dot {
        background-color: themed("sentStatusColor");
      }
    }
  }
  .status-draft {
    @include themify($themes) {
      color: themed("draftStatusColor");
      .dot {
        background-color: themed("draftStatusColor");
      }
    }
  }
  .status-settled {
    @include themify($themes) {
      color: themed("settledStatusColor");
      .dot {
        background-color: themed("settledStatusColor");
      }
    }
  }
  .status-canceled {
    @include themify($themes) {
      color: themed("cancelledStatusColor");
      .dot {
        background-color: themed("cancelledStatusColor");
      }
    }
  }
  .status-received {
    @include themify($themes) {
      color: themed("receivedStatusColor");
      .dot {
        background-color: themed("receivedStatusColor");
      }
    }
  }
  .status-confirmed {
    @include themify($themes) {
      color: themed("confirmedStatusColor");
      .dot {
        background-color: themed("confirmedStatusColor");
      }
    }
  }
  .status-validated {
    @include themify($themes) {
      color: themed("validatedStatusColor");
      .dot {
        background-color: themed("validatedStatusColor");
      }
    }
  }
  .status-partially-received {
    @include themify($themes) {
      color: themed("partiallyReceivedStatusColor");
      .dot {
        background-color: themed("partiallyReceivedStatusColor");
      }
    }
  }
  .status-active {
    @include themify($themes) {
      color: themed("activeStatusColor");
      .dot {
        background-color: themed("activeStatusColor");
      }
    }
  }
  .status-inactive {
    @include themify($themes) {
      color: themed("inactiveStatusColor");
      .dot {
        background-color: themed("inactiveStatusColor");
      }
    }
  }
  .status-renovation {
    @include themify($themes) {
      color: themed("renovationStatusColor");
      .dot {
        background-color: themed("renovationStatusColor");
      }
    }
  }
  .status-liquidation {
    @include themify($themes) {
      color: themed("liquidationStatusColor");
      .dot {
        background-color: themed("liquidationStatusColor");
      }
    }
  }
  .status-obsolete {
    @include themify($themes) {
      color: themed("obsoleteStatusColor");
      .dot {
        background-color: themed("obsoleteStatusColor");
      }
    }
  }
  .status-to-process {
    @include themify($themes) {
      color: themed("toProcessStatusColor");
      .dot {
        background-color: themed("toProcessStatusColor");
      }
    }
  }
  .status-in-progress {
    @include themify($themes) {
      color: themed("inProgressStatusColor");
      .dot {
        background-color: themed("inProgressStatusColor");
      }
    }
  }
  .status-exported {
    @include themify($themes) {
      color: themed("exportedStatusColor");
      .dot {
        background-color: themed("exportedStatusColor");
      }
    }
  }
  .status-finished {
    @include themify($themes) {
      color: themed("finishedStatusColor");
      .dot {
        background-color: themed("finishedStatusColor");
      }
    }
  }
  .status-pending {
    @include themify($themes) {
      color: themed("pendingStatusColor");
      .dot {
        background-color: themed("pendingStatusColor");
      }
    }
  }
  .status-refused {
    @include themify($themes) {
      color: themed("refusedStatusColor");
      .dot {
        background-color: themed("refusedStatusColor");
      }
    }
  }
  .status-running {
    @include themify($themes) {
      color: themed("runningStatusColor");
      .dot {
        background-color: themed("runningStatusColor");
      }
    }
  }
  .status-done {
    @include themify($themes) {
      color: themed("doneStatusColor");
      .dot {
        background-color: themed("doneStatusColor");
      }
    }
  }
  .status-error {
    @include themify($themes) {
      color: themed("errorStatusColor");
      .dot {
        background-color: themed("errorStatusColor");
      }
    }
  }
  .status-to-receive {
    @include themify($themes) {
      color: themed("toReceiveStatusColor");
      .dot {
        background-color: themed("toReceiveStatusColor");
      }
    }
  }
  .status-preparation-in-progress {
    @include themify($themes) {
      color: themed("preparationInProgressStatusColor");
      .dot {
        background-color: themed("preparationInProgressStatusColor");
      }
    }
  }
  .status-receive-in-progress {
    @include themify($themes) {
      color: themed("receiveInProgressStatusColor");
      .dot {
        background-color: themed("receiveInProgressStatusColor");
      }
    }
  }
}
