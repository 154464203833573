.ngx-datatable {
  .datatable-tree-button {
    display: none;
  }
  @include themify($themes) {
    color: themed("textColor");
    background-color: themed("tableBackgroundColor");
  }
  font-size: $fugu_font_size;

  .datatable-header {
    display: flex !important;
    @include themify($themes) {
      background-color: themed("tableHeadBackgroundColor");
    }
    .datatable-header-cell {
      display: flex;
      align-items: center;
      .green-zone {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
        margin-top: 1px;
        &:hover {
          @include themify($themes) {
            background-color: themed("greenZoneColor");
          }
        }
      }
    }
    .datatable-header-cell-template-wrap {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 13px;
      padding-top: 13px;
      padding-left: 10px;
    }
    .datatable-row-center {
      align-items: center;
      .datatable-header-cell.center-align {
        .datatable-header-cell-template-wrap {
          justify-content: center;
          padding-left: 0;
        }
      }
      .datatable-header-cell.double-line-center {
        .datatable-header-cell-template-wrap {
          white-space: pre-line;
          text-align: center;
          padding-left: 0;
        }
      }
    }
    span {
      text-transform: uppercase;
      font-weight: 800;
    }
    .sort-btn {
      font-weight: 900;
      padding-top: 5px;
      padding-left: 5px;
    }
  }
  .datatable-body-row {
    // apply active pseudo class style wherever a row click trigger an action (the default case)
    cursor: pointer;
    &:active {
      .datatable-body-cell {
        @include themify($themes) {
          background-color: themed("activatedRowBackgroundColor");
        }
      }
    }
    // deactivate the active pseudo class style wherever a row click does not trigger any action
    &.not-clickable {
      cursor: default;
      &:hover {
        span,
        div {
          font-weight: normal;
        }
      }
      &:active {
        .datatable-body-cell {
          @include themify($themes) {
            background-color: themed("hoveredRowBackgroundColor");
          }
        }
      }
    }
    &.strikethrough .datatable-body-cell {
      text-decoration: line-through;
      @include themify($themes) {
        text-decoration-color: themed("strikethroughColor");
      }
      cursor: default;
    }
    &.disabled .datatable-body-cell {
      @include themify($themes) {
        color: themed("deactivatedColor");
      }
      cursor: default;
    }

    &:hover {
      span,
      div {
        font-weight: bold;
      }
      @include themify($themes) {
        background-color: themed("hoveredRowBackgroundColor");
      }
      .fugu-tag {
        font-weight: normal;
      }
    }

    .datatable-body-cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 5px;
      padding-right: 5px;
      line-height: normal;
      border-bottom: 2px solid;
      @include themify($themes) {
        border-bottom-color: themed("tableLineBetweenCellColor");
      }

      &.center-align {
        justify-content: center;
        padding-left: 0;
      }
      &.top-align {
        align-items: flex-start;
        padding-top: 9px;
        padding-bottom: 9px;
        &.break-spaces {
          white-space: break-spaces !important;
        }
      }

      &.hide-overflow {
        overflow-y: hidden;
      }

      &.margin-top-align {
        position: relative;
        margin-top: 30px;
      }

      &.no-strikethrough {
        text-decoration: none;
      }

      &.highlight {
        @include themify($themes) {
          background-color: themed("highLightColor") !important;
        }
      }

      &.ellipsis-counter {
        .datatable-body-cell-label {
          width: 100%;
          display: flex;
          .ellipsis {
            z-index: 1;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            max-width: 70%;
            text-overflow: ellipsis;
            margin-right: 10px;
          }

          .tags-content {
            display: flex !important;
            align-items: center;
            div {
              margin-right: 5px !important;
            }
            white-space: nowrap;
          }
        }
      }

      &.frozen-style {
        @include themify($themes) {
          background-color: themed("tableFrozenCellBackgroundColor");
        }
      }
      .datatable-body-cell-label {
        .green-zone {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: default;
          margin-top: 1px;
          &:hover {
            @include themify($themes) {
              background-color: themed("greenZoneColor");
            }
          }
        }
      }
    }
    &.inError {
      @include themify($themes) {
        background-color: themed("inErrorColor");
        color: themed("inErrorColorText");
      }
      .datatable-body-cell.frozen-style,
      .datatable-body-cell.frozen-style:hover {
        @include themify($themes) {
          background-color: themed("inErrorColor") !important; // override
        }
      }
    }
  }
  .datatable-summary-row {
    .datatable-body-cell {
      @include themify($themes) {
        background-color: themed("tableSummaryBackgroundColor");
      }
      &.highlight {
        @include themify($themes) {
          background-color: themed("tableSummaryBackgroundColor") !important;
        }
      }
      border-top: 2px solid;
      @include themify($themes) {
        border-top-color: themed("designBorderLineColor");
      }
      border-bottom: none;
    }
    .datatable-body-row {
      cursor: default;
      &:active {
        .datatable-body-cell {
          @include themify($themes) {
            background-color: themed("tableBackgroundColor");
          }
        }
      }
    }
  }

  // Pagination pages style
  .pages {
    a {
      min-width: 25px;
      padding: 0 8px;
      line-height: 25px;
      font-size: initial;
      @include themify($themes) {
        color: themed("textColor");
      }
      text-decoration: none;
      text-align: center;

      &:hover {
        @include themify($themes) {
          background: themed("tablePaginationHoveredBackgroundColor");
          border-radius: 25px;
          color: themed("tablePaginationHoverTextColor");
        }
      }
    }

    &.active {
      a {
        font-weight: bold;
        @include themify($themes) {
          color: themed("tablePaginationActiveTextColor");
        }
        &:hover {
          @include themify($themes) {
            color: themed("tablePaginationHoverTextColor");
          }
        }
      }
    }
  }
  // Pagination icons style
  .pager li {
    i {
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 2px;
      @include themify($themes) {
        color: themed("textColor");
      }
      &:hover {
        @include themify($themes) {
          color: themed("tablePaginationHoverTextColor");
        }
      }
    }
    &.disabled {
      i {
        @include themify($themes) {
          color: themed("tablePaginationDeactivateColor");
        }
      }
    }

    &:not(.disabled) {
      i {
        &:hover {
          @include themify($themes) {
            background: themed("tablePaginationHoveredBackgroundColor");
            border-radius: 25px;
          }
        }
      }
    }
  }
  &.overflow {
    overflow: visible;
  }
}

.ngx-datatable.vertical-scroll {
  .datatable-body {
    max-height: 600px;
    overflow: scroll;
  }
  .datatable-body::-webkit-scrollbar:vertical {
    display: none;
  }
}

// class of parent div surrounding datatable when frozen-right is used for center align column
.frozen-right,
.frozen-left {
  .ngx-datatable {
    .datatable-header {
      .datatable-row-right,
      .datatable-row-left {
        display: flex;
        align-items: center;
        @include themify($themes) {
          background-color: themed("tableHeadFrozenBackgroundColor");
        }
        .datatable-header-cell-template-wrap {
          justify-content: center;
          padding-left: 0;
          display: flex;
        }
        .left-align {
          .datatable-header-cell-template-wrap {
            justify-content: left;
            padding-left: 10px !important;
          }
        }
      }
    }

    .datatable-body-row {
      .datatable-row-right,
      .datatable-row-left {
        @include themify($themes) {
          background-color: themed("tableBackgroundColor");
        }
      }

      &:hover {
        .datatable-body-cell {
          &.frozen-style {
            @include themify($themes) {
              background-color: themed("hoveredRowBackgroundColor");
            }
          }
        }
      }
      &:active {
        .datatable-body-cell {
          &.frozen-style {
            @include themify($themes) {
              background-color: themed("activatedRowBackgroundColor");
            }
          }
        }
      }
      &.not-clickable {
        &:active {
          .datatable-body-cell {
            &.frozen-style {
              @include themify($themes) {
                background-color: themed("hoveredRowBackgroundColor");
              }
            }
          }
        }
      }
    }
  }
}

.frozen-left {
  .ngx-datatable {
    .datatable-header {
      .datatable-row-left {
        .line-number {
          .datatable-header-cell-template-wrap {
            padding-left: 10px;
          }
        }
        &::after {
          box-shadow: 5px 0px 5px 0px rgba(1, 43, 93, 0.08);
          position: absolute;
          content: " ";
          height: 100%;
          width: 10px;
          right: 0;
          top: 0;
        }
      }
    }

    .datatable-body-row {
      .datatable-row-left {
        &::after {
          box-shadow: 5px 0px 5px 0px rgba(1, 43, 93, 0.08);
          position: absolute;
          content: " ";
          height: 100%;
          width: 10px;
          right: 0;
          top: 0;
        }
      }
    }

    .datatable-summary-row {
      .datatable-row-left {
        .datatable-body-cell {
          @include themify($themes) {
            background-color: themed("tableSummaryBackgroundColor") !important;
          }
        }
      }
    }
  }
}

.frozen-right {
  .ngx-datatable {
    .datatable-header {
      .datatable-row-right {
        &::before {
          box-shadow: -5px 0px 5px 0px rgba(1, 43, 93, 0.08);
          position: absolute;
          content: " ";
          height: 100%;
          width: 10px;
          left: 0;
          top: 0;
        }
      }
    }

    .datatable-body-row {
      .datatable-row-right {
        width: 100%;
        left: 10px;

        &::before {
          box-shadow: -5px 0px 5px 0px rgba(1, 43, 93, 0.08);
          position: absolute;
          content: " ";
          height: 100%;
          width: 10px;
          left: 0;
          top: 0;
        }
      }
    }

    .datatable-summary-row {
      .datatable-row-right .frozen-style {
        @include themify($themes) {
          background-color: themed("tableSummaryBackgroundColor") !important;
        }
      }
    }
  }
}

// Align cell content & header text to right
::ng-deep .datatable-body-cell {
  &.align-right {
    justify-content: flex-end !important;
    padding-right: 10px !important;

    &:nth-last-child(1) {
      padding-right: 15px !important;
    }
  }
}

::ng-deep .align-value-label {
  display: flex;
  justify-content: flex-end;
  .right {
    display: flex;
    justify-content: flex-end !important;
    padding-right: 5px !important;
  }
  .left {
    overflow: hidden;
    white-space: nowrap;
    width: 30px;
    text-overflow: ellipsis;
  }
}

::ng-deep .datatable-header-cell.align-right {
  .datatable-header-cell-template-wrap {
    justify-content: flex-end;
    padding-right: 5px;
  }

  text-align: right;

  &:nth-last-child(1) {
    .datatable-header-cell-template-wrap {
      padding-right: 10px;
    }
  }
}
